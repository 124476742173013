import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"

export default function Terms() {
  return (
    <Layout>
      <Seo title="Terms Of Use" />
      <div className="shadow p-5">
        <div
          className="container p-5 shadow border"
          style={{ borderRadius: "1rem" }}
        >
          <h1 className="py-4">Terms of Use</h1>
          <div>
            <h2 className="pt-5 pb-4">Introduction</h2>
            <p>
              These Terms of Use (“TOU”) apply to your access and use of
              Day-One's products, services, websites, and apps that you purchase
              or sign up for on Day-One's websites and which are branded as
              “Day-One”, “uhappy?”, “Wufoo” or “GetFeedback” (collectively the
              “Service(s)”). These TOU do not apply to Services which are
              available solely through our enterprise sales channel.
            </p>
            <p>
              Additional service-specific terms apply to some Services
              (“Service-Specific Terms”). Certain country-specific terms may
              also apply to you if you are located outside the Europe
              (“Country-Specific Terms”). We refer to the Service-Specific Terms
              and Country-Specific Terms collectively as “Additional Terms” and
              the combination of these TOU and any applicable Additional Terms
              collectively as these “Terms.”
            </p>
            <p>
              You agree to these Terms by clicking to accept these Terms,
              executing a document that references them, or using the Services.
            </p>
            <p>
              If you will be using the Services on behalf of an organization,
              you agree to these Terms on behalf of that organization and you
              represent that you have the authority to do so. In such case,
              “you” and “your” will refer to that organization.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">1. Fees and Payments</h2>
            <h4 className="py-4">1.1 Fees for Services.</h4>
            <p>
              You agree to pay to Day-One any fees for each Service you purchase
              or use (including any overage fees), in accordance with the
              pricing and payment terms presented to you for that Service. Where
              applicable, you will be billed using the billing method you select
              through your account management page. If you have elected to pay
              the fees by credit card, you represent and warrant that the credit
              card information you provide is correct and you will promptly
              notify Day-One of any changes to such information. Fees paid by
              you are non-refundable, except as provided in these Terms or when
              required by law.
            </p>
            <h4 className="py-4">1.2 Subscriptions.</h4>
            <p>
              Some of our Services are billed on a subscription basis (we call
              these “Subscriptions”). This means that you will be billed in
              advance on a recurring, periodic basis (each period is called a
              “billing cycle”). Billing cycles are typically monthly or annual,
              depending on what subscription plan you select when purchasing a
              Subscription. Your Subscription will automatically renew at the
              end of each billing cycle unless you cancel auto-renewal through
              your online account management page, or by contacting our customer
              support team. YOU MAY CANCEL AUTO-RENEWAL ON YOUR SUBSCRIPTION AT
              ANY TIME, IN WHICH CASE YOUR SUBSCRIPTION WILL CONTINUE UNTIL THE
              END OF THAT BILLING CYCLE BEFORE TERMINATING.
            </p>
            <h4 className="py-4">1.3 Taxes.</h4>
            <p>
              Our prices listed do not include any taxes, levies, duties or
              similar governmental assessments of any nature such as
              value-added, sales, use or withholding taxes, assessable by any
              jurisdiction (collectively, “Taxes”) unless otherwise indicated.
              You are responsible for paying Taxes associated with your purchase
              and keeping your billing information up to date.
            </p>
            <ul>
              <li>
                <p>
                  (a) Greece Sales Tax. If we have a legal obligation to pay or
                  collect sales tax for which you are responsible, we will
                  calculate the sales tax based upon the billing information we
                  have about you and charge you that amount (which, if your
                  billing information is incomplete or inaccurate, may be the
                  highest prevailing rate then in effect), unless you timely
                  provide us with a valid tax exemption certificate acceptable
                  to the appropriate taxing authority.
                </p>
                <p>
                  To be timely, you must provide us with a tax exemption
                  certificate before your initial purchase or upgrade, or, if
                  you miss that mark, within 90 days after such purchase or
                  upgrade, unless your billing information is in Alabama,
                  Louisiana, Maine, Massachusetts, Pennsylvania, or South
                  Carolina in which case within 60 days; or if in Hawaii,
                  Mississippi, or New Mexico within 45 days.
                </p>
                <p>
                  If you provide us with a tax exemption certificate, you
                  represent and warrant that it accurately reflects your tax
                  status and that you will keep such document current and
                  accurate.
                </p>
                <p>
                  If we have collected sales tax from you and subsequently
                  determine in our sole discretion that your tax exemption
                  certificate is valid, we will refund the sales tax collected
                  based on applicable state tax laws.
                </p>
              </li>

              <li>
                <p>
                  (b) Non-European Sales Tax. If applicable, we will charge you
                  VAT, GST or any other sales, consumption or use taxes that
                  arise in connection with your purchases of Day-One Services
                  unless you provide us with a tax identification number that
                  entitles you to an exemption, a valid tax exemption
                  certificate or other documentary proof issued by an
                  appropriate taxing authority that tax should not be charged.
                  If you are located in a jurisdiction with multiple sales,
                  consumption or use taxes, we may charge you the highest
                  prevailing rate if your billing information is incomplete or
                  inaccurate.
                </p>
              </li>
            </ul>
            <p>
              If you are required by law to withhold any Taxes from your
              payments to Day-One, you must provide Day-One with an official tax
              receipt or other appropriate documentation to support such
              payments.
            </p>
            <h4 className="py-4">1.4 Price Changes.</h4>
            <p>
              Day-One may change the fees charged to you for the Services at any
              time, provided that, for Subscriptions, the change will become
              effective only at the end of the then-current billing cycle of
              your Subscription. Day-One will provide you with advance notice of
              any change in fees.
            </p>
            <h4 className="py-4">1.5 Overage Fees.</h4>
            <p>
              Unless otherwise stated, any overage fees incurred by you will be
              billed in arrears. Overage fees which remain unpaid for 30 days
              after being billed are considered overdue. Failure to pay overage
              fees when due may result in the applicable Service being limited,
              suspended, or terminated (subject to applicable legal
              requirements), which may result in a loss of your data associated
              with that Service subject to applicable law.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">2. Privacy</h2>
            <h4 className="py-4">2.1 Privacy.</h4>
            <p>
              In the course of using the Services, you may submit content to
              Day-One (including your personal data and the personal data of
              others) or third parties may submit content to you through the
              Services (all of the above will be referred to as your “Content”).
              We know that by giving us your Content, you are trusting us to
              treat it appropriately. Day-One’s Privacy Notice, together with
              any Service-specific privacy notices or statements (collectively,
              “Day-One privacy notices”), detail how we treat your Content and
              we agree to adhere to those Day-One privacy notices. You in turn
              agree that Day-One may use and share your Content in accordance
              with the Day-One privacy notices and applicable data protection
              laws. You also agree that you are responsible for notifying these
              third parties about the Day-One privacy notices. Our Data
              Processing Agreement also apply to and are supplemental to these
              Terms. Where there is a conflict between the Data Processing
              Agreement and these Terms, the Data Processing Agreement will
              prevail except with respect to Exclusion and Limitation of
              Liability where these Terms will prevail.
            </p>
            <h4 className="py-4">2.2 Confidentiality.</h4>
            <p>
              Day-One will treat your Content as confidential information and
              only use and disclose it in accordance with these Terms (including
              the Day-One privacy notices). However, your Content is not
              regarded as confidential information if such Content: (a) is or
              becomes public (other than through breach of these Terms by
              Day-One); (b) was lawfully known to Day-One before receiving it
              from you; (c) is received by Day-One from a third party without
              knowledge of breach of any obligation owed to you; (d) is shared
              in the context of your account being identified by you as a
              business owned account or migrated to an organization’s Enterprise
              account, if your account is registered using a work email address
              within that organization; or (e) was independently developed by
              Day-One without reference to your Content. Day-One may disclose
              your Content when required by law or legal process, but only after
              Day-One, if permitted by law, uses commercially reasonable efforts
              to notify you to give you the opportunity to challenge the
              requirement to disclose.
            </p>
            <h4 className="py-4">2.3 Security.</h4>
            <p>
              Day-One will store and process your Content in a manner consistent
              with industry security standards. Day-One has implemented
              appropriate technical, organizational, and administrative systems,
              policies, and procedures.
            </p>
            <p>
              If Day-One becomes aware of any unauthorized or unlawful access
              to, or acquisition, alteration, use, disclosure, or destruction
              of, personal data related to your account (“Security Incident”),
              Day-One will take reasonable steps to notify you without undue
              delay, but in any event within 72 hours of becoming aware of the
              Security Incident impacting your account. Day-One will also
              reasonably cooperate with you with respect to any investigations
              relating to a Security Incident, any required notices, and
              providing information reasonably requested by you and available to
              us in relation to any Security Incident, where such information is
              not already available to you in your account or online through
              updates provided by Day-One.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">3. Your Content</h2>
            <h4 className="py-4">3.1 You Retain Ownership of Your Content.</h4>
            <p>
              You retain ownership of all of your intellectual property rights
              in your Content. Day-One does not claim ownership over any of your
              Content. These Terms do not grant us any licenses or rights to
              your Content except for the limited license described in these
              Terms.
            </p>
            <h4 className="py-4">3.2 Limited License to Your Content.</h4>
            <p>
              You grant Day-One a worldwide, royalty free license to use,
              reproduce, distribute, modify, adapt, create derivative works,
              make publicly available, and otherwise exploit your Content, but
              only for the limited purposes of providing and improving the
              Services and as permitted by the Day-One privacy notices. This
              license for such limited purposes continues even after you stop
              using our Services, with respect to aggregate and de-identified
              data derived from your Content and any residual backup copies of
              your Content made in the ordinary course of Day-One's business
              (subject to our retention policies). This license also extends to
              any trusted third parties we work with to the extent necessary to
              provide and improve the Services.
            </p>
            <h4 className="py-4">3.3 Representations and Warranties.</h4>
            <p>
              You represent and warrant that: (a) you own or control the
              appropriate rights in and to your Content, including any
              intellectual property owned by third parties; and (b) you will not
              submit, upload, or otherwise make available via the Services, any
              Content or materials that are in breach of our Acceptable Uses
              Policy.
            </p>
            <h4 className="py-4">3.4 Responsibility for Your Content.</h4>
            <p>
              The Services may display content not owned by Day-One but by
              others. The entity that makes such content available is
              responsible for it. You are responsible for your Content, and you
              must ensure that you have all the rights and permissions needed to
              use that Content in connection with the Services. Day-One is not
              responsible for any actions you take with respect to your Content,
              including sharing it publicly. Subject to applicable law, Day-One
              is not liable for your Content, any other third-party content or
              materials, or any loss or damage resulting from your use of, or
              reliance on, such Content or other third-party content or
              materials.
            </p>
            <p>
              You acknowledge that, to ensure compliance with legal obligations,
              Day-One may be required to review certain of your Content
              submitted to the Services to determine whether it is illegal or
              whether it violates these Terms (such as when unlawful content is
              reported to us). We may also modify, prevent access to, delete, or
              refuse to display your Content that we believe violates the law or
              these Terms. In the event your Content includes third-party
              brands, logos or other source identifiers, we may require you to
              submit a statement of non-affiliation before you may use such
              Content in connection with the Services. However, Day-One
              otherwise has no obligation to monitor or review any content
              submitted to the Services.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">4. IP Claims</h2>
            <h4 className="py-4">4.1 DMCA Notices or Equivalents.</h4>
            <p>
              Day-One responds to notices of alleged copyright infringement in
              accordance with the Greek Digital Millennium Copyright Act or
              equivalent applicable laws and regulations. If you believe that
              your work has been exploited in a way that constitutes copyright
              infringement, you may notify our agent for claims of copyright
              infringement.
            </p>
            <h4 className="py-4">4.2 Other IP Claims.</h4>
            <p>
              If you believe a Day-One user is infringing upon your intellectual
              property rights, you may report it through our online form. Claims
              of copyright infringement should follow the process outlined in
              these Terms, or any equivalent process available under local law.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">5. Day-One IP</h2>
            <p>
              Neither these Terms nor your use of the Services grants you
              ownership in the Services or the content you access through the
              Services (other than your Content). Except as permitted by our
              Brand and Trademark Use Policy, these Terms do not grant you any
              right to use Day-One’s trademarks or other brand elements.
            </p>
            <p>
              If you submit any feedback or suggestions to us regarding our
              Services, we may use and share them for any purpose without any
              compensation or obligation to you.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">6. Third Party Resources</h2>
            <p>
              Day-One may publish links in its Services to internet websites
              maintained by third parties. Day-One does not represent that it
              has reviewed such third party websites and is not responsible for
              them or any content appearing on them. Trademarks displayed in
              conjunction with the Services are the property of their respective
              owners.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">7. Account Management</h2>
            <h4 className="py-4">7.1 Keep Your Password Secure.</h4>
            <p>
              If you have been issued an account by Day-One in connection with
              your use of the Services, you are responsible for safeguarding
              your password and any other credentials used to access that
              account. You, and not Day-One, are responsible for any activity
              occurring in your account (other than activity that Day-One is
              directly responsible for which is not performed in accordance with
              your instructions), whether or not you authorized that activity.
              If you become aware of any unauthorized access to your account,
              you should notify Day-One immediately. Accounts may not be shared
              and may only be used by one individual per account.
            </p>
            <h4 className="py-4">
              7.2 Keep Your Email and Account Details Accurate.
            </h4>
            <p>
              Day-One occasionally sends notices to the email address registered
              with your account. You must keep your email address and, where
              applicable, your contact details and payment details associated
              with your account current and accurate.
            </p>

            <h4 className="py-4">7.3 Remember to Backup.</h4>
            <p>
              You are responsible for maintaining, protecting, and making
              backups of your Content. To the extent permitted by applicable
              law, Day-One will not be liable for any failure to store, or for
              loss or corruption of, your Content.
            </p>

            <h4 className="py-4">7.4 Account Inactivity.</h4>
            <p>
              Day-One may terminate your account and delete any Content
              contained in it if there is no account activity (such as a log in
              event or payment) for over 12 months. However, where appropriate,
              we will attempt to warn you by email before terminating your
              account to provide you with an opportunity to log in to your
              account so that it remains active.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">8. User Requirements</h2>
            <h4 className="py-4">8.1 Legal Status.</h4>
            <p>
              If you are an individual, you may only use the Services if you
              have the power to form a contract with Day-One. If you do not have
              the power to form a contract, you may not use the Services. If you
              are not an individual, you warrant that you are validly formed and
              existing under the laws of your jurisdiction of formation, that
              you have full power and authority to enter into these Terms, and
              that you have duly authorized your agent to bind you to these
              Terms.
            </p>
            <h4 className="py-4">8.2 Minors.</h4>
            <p>
              “Minors” are individuals under the age of 13 (or under a higher
              age as provided in certain countries and territories). None of the
              Services are intended for use by Minors. If you are a Minor in
              your place of residence, you may not use the Services. By using
              the Services, you represent and warrant that you are not a Minor.
            </p>

            <h4 className="py-4">8.3 Embargoes.</h4>
            <p>
              You may only use the Services if you are not barred under any
              applicable laws from doing so. If you are located in a country
              embargoed by Greece or other applicable law from receiving the
              Services or you are on the Greek Department of Commerce’s Denied
              Persons List or Entity List or the Greek Treasury Department’s
              list of Specially Designated Nationals, you are not permitted to
              purchase any paid Services from Day-One. You will ensure that: (a)
              your end users do not use the Services in violation of any export
              restriction or embargo by the Greece; and (b) you do not provide
              access to the Services to persons or entities on any of the above
              lists.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">9. Acceptable Uses Policy</h2>
            <p>
              You agree to comply with the <a href="">Acceptable Uses Policy</a>
              .
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">10. PCI Compliance</h2>
            <h4 className="py-4">10.1 PCI Standards.</h4>
            <p>
              If you use the Services to accept payment card transactions, you
              must comply with the Payment Card Industry Data Security Standards
              (PCI-DSS) to the extent they are applicable to your business (the
              “PCI Standards”). Day-One provides tools to simplify your
              compliance with the PCI Standards, but you must ensure that your
              business is compliant and the specific steps you will need to take
              to comply with the PCI Standards will depend on your
              implementation of the Services.
            </p>

            <h4 className="py-4">10.2 Cardholder Data.</h4>
            <p>
              Day-One is responsible for the security of Cardholder Data that is
              collected, transmitted, stored, or processed by us on your behalf.
              “Cardholder Data” is defined as a cardholder’s primary account
              number, and where a full unmasked card number is present, any of
              the cardholder name, expiration date, and/or service code. Day-One
              has developed strict security features to protect Cardholder Data,
              and as such this data may only be used in anticipated ways and
              stored in appropriate places. YOU ACKNOWLEDGE AND AGREE THAT YOU
              ARE PROHIBITED FROM COLLECTING OR ENTERING CARDHOLDER DATA INTO
              ANY FORM OR DATA ENTRY FIELDS IN THE SERVICES, EXCEPT INTO THOSE
              FIELDS INTENDED SOLELY FOR THAT PURPOSE (i.e. where Day-One
              explicitly enables such data to be entered into such fields).
              Appropriate fields are clearly marked with labels such as ‘Card
              number’ or by having a credit card icon precede them. Similarly,
              excluding payment forms, you must never collect or enter any
              “Sensitive Authentication Data”, as defined by the PCI Standards
              (including CVC or CVV2) into any fields in the Services. You
              assume all responsibility for any Cardholder Data entered into the
              Services in violation of these terms.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">
              11. Suspension and Termination of Services
            </h2>
            <h4 className="py-4">11.1 By You.</h4>
            <p>
              You can terminate your Subscription and delete your account at any
              time through your account management page. Such termination and
              deletion will result in the deactivation or disablement of your
              account and access to it, and the deletion of content you
              collected through use of the Services. Terminations are confirmed
              immediately, and you will not be charged again for that
              Subscription unless you purchase a new one. If you terminate a
              Subscription in the middle of a billing cycle, you will not
              receive a refund unless you are terminating these Terms for any of
              the following reasons: (a) we have materially breached these Terms
              and failed to cure that breach within 30 days after you have so
              notified us in writing; (b) a refund is required by law; or (c)
              we, in our sole discretion, determine a refund is appropriate. For
              clarity, we will not grant a refund where you have used our
              Services, collected responses, and/or downloaded your responses
              unless the termination is due to our material, uncured breach or a
              refund is required by law.
            </p>

            <h4 className="py-4">11.2 By Day-One.</h4>
            <p>
              (a) For Convenience. Day-One may terminate your Subscription
              effective at the end of a billing cycle by providing at least 30
              days’ prior written notice to you without refund for any prior
              period. Additionally, Day-One may terminate your Subscription at
              any time during the billing cycle by providing at least 90 days’
              written notice to you and will provide a pro rata refund for any
              period of time you did not use in that billing cycle.
            </p>
            <p>
              (b) For Cause. Day-One may suspend performance or terminate your
              Subscription for any of the following reasons: (a) you have
              materially breached these Terms and failed to cure that breach
              within 30 days after Day-One has so notified you in writing; (b)
              you cease your business operations or become subject to insolvency
              proceedings and the proceedings are not dismissed within 90 days;
              or (c) you fail to pay fees for 30 days past the due date.
              Additionally, Day-One may limit, suspend, or terminate the
              Services to you: (i) if you fail to comply with these Terms, (ii)
              if you use the Services in a way that causes legal liability to us
              or disrupts others’ use of the Services; or (iii) if we are
              investigating suspected misconduct by you. Also, if we limit,
              suspend, or terminate the Services you receive, depending upon the
              reason, we will endeavor to give you advance notice and an
              opportunity to obtain a copy of your Content from that Service.
              However, there may be time sensitive situations where Day-One may
              decide that we need to take immediate action without notice.
              Day-One will use commercially reasonable efforts to narrow the
              scope and duration of any limitation or suspension under this
              Section as is needed to resolve the issue that prompted such
              action. Day-One has no obligation to retain your Content upon
              termination of the applicable Service.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">12. Changes and Updates</h2>
            <h4 className="py-4">12.1 Changes to Terms.</h4>
            <p>
              Day-One may change these Terms at any time for a variety of
              reasons, such as to reflect changes in applicable law or updates
              to Services, and to account for new Services or functionality. The
              most current version will always be posted on the Day-One website.
              If an amendment is material, as determined in Day-One’s sole
              discretion, Day-One will notify you by email. Notice of amendments
              may also be posted upon your login to your account. Except as
              otherwise specified by us, changes will be effective no sooner
              than the day they are publicly posted. If you do not want to agree
              to any changes made to the terms for a Service, you should stop
              using that Service, because (unless otherwise stated in law) by
              continuing to use the Services you indicate your agreement to be
              bound by the updated terms.
            </p>

            <h4 className="py-4">12.2 Changes to Services.</h4>
            <p>
              Day-One constantly changes and improves the Services. Day-One may
              add, alter, or remove functionality from a Service it provides to
              you at any time without prior notice. Day-One may also limit,
              suspend, or discontinue a Service provided to you at its
              discretion. If Day-One discontinues a Service, we will give you
              reasonable advance notice to provide you with an opportunity to
              obtain a copy of your Content from that Service. Day-One may
              remove content from the Services it provides you at any time in
              our sole discretion, although we will endeavor to notify you
              before we do that if it materially impacts you and if practicable
              under the circumstances.
            </p>
            <h4 className="py-4">12.3 Downgrades.</h4>
            <p>
              Downgrading your account plan may cause the loss of Content,
              features, functionality, or capacity of your account.
            </p>
            <h4 className="py-4">
              12.4 uhappy? Basic (Free) Plan Response Limits.
            </h4>
            <p>
              Any responses over your uhappy? Basic (free) plan's response
              limits will not be viewable, and each response over the limit will
              be deleted 60 days after it is received, unless you upgrade to a
              uhappy? paid plan to view and keep access to all responses before
              they are deleted. We encourage you to go to My Surveys to see
              which surveys have extra responses over your plan's response
              limit, in case you want to upgrade to a paid plan to view and keep
              them.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">
              13. Disclaimers, Limitations of Liability and Indemnification
            </h2>
            <h4 className="py-4">13.1 Disclaimers.</h4>
            <p>
              While it is in Day-One’s interest to provide you with a great
              experience when using the Services, there are certain things we do
              not promise about them. We try to keep our online Services up, but
              they may be unavailable from time to time for various reasons.
              EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS AND TO THE EXTENT
              PERMITTED BY APPLICABLE LAW, THE SERVICES AND ANY GUIDANCE OR
              RECOMMENDATIONS THEREIN ARE PROVIDED “AS IS” AND Day-One DOES NOT
              MAKE WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, OR STATUTORY,
              INCLUDING THOSE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, AND NON-INFRINGEMENT OR ANY REPRESENTATIONS REGARDING
              AVAILABILITY, RELIABILITY, OR ACCURACY OF THE SERVICES.
            </p>

            <h4 className="py-4">13.2 Exclusion of Certain Liability.</h4>
            <p>
              TO THE EXTENT PERMITTED BY APPLICABLE LAW, Day-One (INCLUDING ITS
              AFFILIATES AND ITS AND THEIR OFFICERS, EMPLOYEES, AGENTS,
              SUPPLIERS, AND LICENSORS) WILL NOT BE LIABLE FOR (A) ANY INDIRECT,
              CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES
              WHATSOEVER, OR (B) LOSS OF USE, DATA, BUSINESS, REVENUES, OR
              PROFITS (IN EACH CASE WHETHER DIRECT OR INDIRECT), ARISING OUT OF
              OR IN CONNECTION WITH THE SERVICES AND THESE TERMS, AND WHETHER
              BASED ON CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL
              THEORY, EVEN IF Day-One HAS BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
            </p>
            <h4 className="py-4">13.3 Limitation of Liability.</h4>
            <p>
              TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE AGGREGATE LIABILITY
              OF Day-One (INCLUDING ITS AFFILIATES AND ITS AND THEIR OFFICERS,
              EMPLOYEES, AGENTS, SUPPLIERS, AND LICENSORS) ARISING OUT OF OR IN
              CONNECTION WITH THE SERVICES AND THESE TERMS WILL NOT EXCEED THE
              LESSER OF: (A) THE AMOUNTS PAID BY YOU TO Day-One FOR USE OF THE
              SERVICES AT ISSUE DURING THE 12 MONTHS PRIOR TO THE EVENT GIVING
              RISE TO THE LIABILITY; AND (B) US$200.00.
            </p>
            <p>
              IN COUNTRIES WHERE THE ABOVE TYPES OF EXCLUSIONS (SECTION 13.2)
              AND/OR LIMITATIONS (SECTION 13.3) ARE NOT PERMITTED BY LAW, WE ARE
              RESPONSIBLE TO YOU ONLY FOR YOUR LOSSES AND DAMAGES THAT ARE A
              REASONABLY FORESEEABLE RESULT OF OUR FAILURE TO USE ALL REASONABLE
              SKILL AND CARE OR THAT DIRECTLY RESULT FROM A MATERIAL BREACH OF
              THESE TERMS.
            </p>
            <h4 className="py-4">13.4 Consumers.</h4>
            <p>
              While our Services are most often used for business, we
              acknowledge that the laws of certain jurisdictions provide legal
              rights to consumers that may not be overridden by contract or
              waived by those consumers. If you are such a consumer using our
              Services for non-business reasons, nothing in these Terms limits
              any of those consumer rights.
            </p>
            <h4 className="py-4">13.5 Indemnification.</h4>
            <p>
              If you are a business, you will indemnify and hold harmless
              Day-One (including its affiliates and its and their officers,
              agents, and employees) from all liabilities, damages, and costs
              (including settlement costs and reasonable attorneys’ fees)
              arising out of a third party claim regarding or in connection with
              your or your end users’ use of the Services or breach of these
              Terms, to the extent that such liabilities, damages and costs were
              caused by you or your end users.
            </p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">14. Contracting Entity</h2>
            <h4 className="py-4">14.1 Who you are contracting with.</h4>
            <p>
              Unless otherwise noted, the Services are provided by, and you are
              contracting with, Day-One P.C. inside of Greece.
            </p>

            <h4 className="py-4">14.2 Day-One P.C.</h4>
            <p>
              For any Service provided by Day-One P.C., the following provisions
              will apply to any terms governing that Service:
            </p>

            <ul>
              <li>
                Contracting Entity. References to “Day-One”, “we”, “us”, and
                “our” are references to Day-One P.C., located at Zaloggou 1-5,
                Galatsi, 111 46, Greece.
              </li>
              <li>
                Contracting Entity. References to “Day-One”, “we”, “us”, and
                “our” are references to Day-One P.C., located at Zaloggou 1-5,
                Galatsi, 111 46, Greece.
              </li>

              <li>
                Jurisdiction. Except if prohibited by applicable law, each party
                submits to the exclusive jurisdiction of the state courts
                located in San Mateo County, California, and the federal courts
                located in the Northern District of California with respect to
                the subject matter of those terms.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="pt-5 pb-4">15. Other Terms</h2>
            <h4 className="py-4">15.1 Assignment.</h4>
            <p>
              You may not assign these Terms without Day-One’s prior written
              consent, which may be withheld in Day-One’s sole discretion.
              Day-One may assign these Terms at any time without notice to you.
            </p>

            <h4 className="py-4">15.2 Customer Lists.</h4>
            <p>
              Day-One may identify you by name and logo as a customer of the
              Services on our websites and on other promotional materials. Any
              goodwill arising from the use of your name and logo will inure to
              your benefit.
            </p>
            <h4 className="py-4">15.3 Entire Agreement.</h4>
            <p>
              These Terms (including the Additional Terms) constitute the entire
              agreement between you and Day-One, and they supersede any other
              prior or contemporaneous agreements, terms and conditions, written
              or oral concerning its subject matter. Any terms and conditions
              appearing on a purchase order or similar document issued by you do
              not apply to the Services, do not override or form a part of these
              Terms, and are void.
            </p>

            <h4 className="py-4">15.4 Independent Contractors.</h4>
            <p>
              The relationship between you and Day-One is that of independent
              contractors, and not legal partners, employees, or agents of each
              other.
            </p>

            <h4 className="py-4">15.5 Interpretation.</h4>
            <p>
              The use of the terms “includes”, “including”, “such as”, and
              similar terms, will be deemed not to limit what else might be
              included.
            </p>
            <h4 className="py-4">15.6 Language.</h4>
            <p>
              These Terms are prepared and written in English. To the extent
              that any translated version conflicts with the English version,
              the English version controls, except where prohibited by
              applicable law.
            </p>

            <h4 className="py-4">15.7 No Waiver.</h4>
            <p>
              A party’s failure or delay to enforce a provision under these
              Terms is not a waiver of its right to do so later.
            </p>

            <h4 className="py-4">15.8 Precedence.</h4>
            <p>
              To the extent any conflict exists, the Additional Terms prevail
              over these TOU with respect to the Services to which the
              Additional Terms apply.
            </p>

            <h4 className="py-4">15.9 Severability.</h4>
            <p>
              If any provision of these Terms is determined to be unenforceable
              by a court of competent jurisdiction, that provision will be
              severed and the remainder of terms will remain in full effect.
            </p>

            <h4 className="py-4">15.10 Third Party Beneficiaries.</h4>
            <p>There are no third party beneficiaries to these Terms.</p>
          </div>
          <div>
            <h2 className="pt-5 pb-4">16. Terms for Certain Customers</h2>
            <p>
              The following amendments automatically apply to you upon
              acceptance of these Terms if you are one of the types of entities
              identified below:
            </p>

            <ul>
              <li>
                If you are a European Government Agency, this Amendment applies
                to you.
              </li>
              <li>
                you are a different type of government entity in the Europe,
                this Amendment applies to you.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
